<template>
  <v-container style="min-height: 100vh;">
    <client-assign-consult v-if="$store.getters.userHasRole('customer')"></client-assign-consult>
  </v-container>
</template>

<script>

import clientAssignConsult from "../../components/AssignConsult/clientAssignConsult";

export default {
  components: {
    clientAssignConsult
  }
}
</script>

<style scoped>

</style>