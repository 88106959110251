<template>
    <v-container>
        <v-dialog
                v-model="paymentDialog"
                max-width="500px"
        >
            <v-card>
                <v-card-text>
                    <h3 class="pt-10 text-center font-weight-bold" style="font-size: 1.5em;" :class="transactionClass">
                        {{message}}
                    </h3>
                </v-card-text>
                <v-card-text>
                    <v-card-subtitle class="pb-0 pt-5 text-center text--secondary" v-if="transactionRefId != null">
                      {{codeTrackingCode}}
                      : {{transactionRefId}}
                    </v-card-subtitle>
                    <v-spacer></v-spacer>
                </v-card-text>
                <v-card-actions class="justify-center py-5">
                    <v-btn
                        :id="btn_id"
                            color="success"
                            @click="redirectToStepper"
                            rounded
                        :class="classes"
                    >
                        {{this.action}}
                    </v-btn>
                    <!--<v-btn @click="sendAgain">send again</v-btn>-->
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    /*eslint-disable*/
    // import Vue from 'vue';
    // import Tracker from '../../tracker';

    export default {

        components: {},
        data() {
            return {
              classes:'',
              btn_id:null,
                paydata: null,
                sendingChoices: false,
                profile: null,
                loading: true,
                candidateCounselor: {},
                cycleId: null,
                days: [],
                selectedDays: [],
                selectedDaysList: [],
                remainCount: 0,
                unfitCounselor: '',
                unfitTime: '',
                todoMessage: '',
                showBuyPackage: false,
                canSelect: false,
                dialogRemainTime: false,
                dialogChangeCounselor: false,
                paymentDialog: false,
                successfulPayment: {
                    refId: '',
                    trCode: '',
                },
                nextSession: {},
                message: '',
                transactionClass: null,
                transactionRefId: null,
                validOrder: false,

            }
        },
        computed: {
            suggestedDays() {
                return this.days;
            },
          getLang() {
            return localStorage.getItem('local') || "fa"
          },
          successTitle() {
            if (this.getLang == 'fa') {
              return "پرداخت با موفقیت انجام شد"
            }
            return "Payment was successful"
          },
          action() {
            if (this.getLang == 'fa') {
              return " تایید"
            }
            return "confirm"
          },
          codeTrackingCode() {
            if (this.getLang == 'fa') {
              return "  شماره پیگیری"
            }
            return "Tracking Code"
          },
          failedTitle() {
            if (this.getLang == 'fa') {
              return "پرداخت با شکست مواجه شد"
            }
            return "Payment failed"
          }
        },
        mounted() {
          console.log(this.getLang)
            this.$store.dispatch('getUserInfo').then(() => {
                this.profile = this.$store.getters.getUserInfo;
                this.processPayment();
              this.checkSuccessPayment();
            })

        },
        watch: {
            paymentDialog(newVal) {
                if (!newVal) {
                    this.redirectToStepper();
                }
            }
        },
        methods: {
            processPayment(){
              if(this.$route.query.status == "paid"){
                // this.transactionClass = 'success';
                this.transactionClass = 'green--text';
                this.message = this.successTitle;
                this.btn_id = 'ga_purchase_successful';
              }else{
                this.btn_id = 'ga_purchase_failed';
                this.transactionClass = 'red--text';
                this.transactionRefId = null;
                this.message = this.failedTitle;
                this.validOrder = false;
              }
              this.paymentDialog = true
              this.transactionRefId = this.$route.query.ref_id || null;
              this.successfulPayment.trCode = this.$route.query.trCode || null;
            },
            sendWebEngagePaymentData(eventName,data) {
                window.webengage.track(eventName, data);
            },
            // sendAgain() {
            //     this.$gtm.trackEvent(this.paydata);
            // },
            getCookie: function (cname) {
                var name = cname + "=";
                var decodedCookie = decodeURIComponent(document.cookie);
                var ca = decodedCookie.split(';');
                for (var i = 0; i < ca.length; i++) {
                    var c = ca[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            },
            redirectToStepper() {
                this.$router.push({path: this.getLang+'/panel/dashboard'});
                return;
            },


            checkSuccessPayment() {
                if (this.$route.query.hasOwnProperty('successful_payment')) {

                    // let authority = null;
                    let payment = this.$route.query.successful_payment;
                    // if (this.$route.query.hasOwnProperty('Authority')) authority = this.$route.query.Authority;

                    this.$store.dispatch('getTransactionInformation', payment)
                        .then((resp) => {

                            if (resp.data.msg == "success payment") {
                                let currency = resp.data.currency == 'dollar' ? 'USD' : 'RIAL';
                                this.transactionMessage = this.successTitle;
                                this.btn_id = 'ga_purchase_successful';
                                // this.transactionClass = 'success';
                                let order = resp.data.order;
                                let conversionRate = resp.data.conversion_rate;
                                this.successfulPayment.refId = resp.data.ref_id;
                                if(resp.data.newCustomer) {
                                  this.classes = 'new-customer';
                                }




                                // if (window.location.hostname.includes('simiaroom')) {


                                let products = [];
                                let webengageProducts = [];

                                order.products.forEach((item, index) => {

                                    webengageProducts[`product_id_${(index + 1)}`] = item.id;
                                    webengageProducts[`product_name_${(index + 1)}`] = item.title;
                                    webengageProducts[`product_discount_${(index + 1)}`] = parseFloat((item.pivot.discount * conversionRate).toFixed(2));
                                    webengageProducts[`product_item_count_${(index + 1)}`] = item.pivot.quantity;
                                    webengageProducts[`product_price_${(index + 1)}`] = parseFloat((item.pivot.price * conversionRate).toFixed(2));
                                    webengageProducts[`product_session_count_${(index + 1)}`] = item.session_count;

                                    products.push({
                                        'name': item.title,
                                        'id': item.id,
                                        'price': item.pivot.price,
                                        'brand': 'Simiaroom',
                                        'category': 'Counseling',
                                        'quantity': item.pivot.quantity
                                    });
                                });


                                let ecommerce = {
                                    purchase: {
                                        actionField: {
                                            id: resp.data.trCode,
                                            revenue: order.subtotal //<%=sales.total%>
                                        },
                                        products
                                    }
                                }
                                // console.log(ecommerce);
                                if (order.discount == 0) {
                                    ecommerce.purchase.actionField.coupon = '';
                                } else {
                                    ecommerce.purchase.actionField.coupon = order.discount;
                                }


                                let webEngageData = {
                                    currency: 'dollar',
                                    isFirstPurchase:(resp.data.newCustomer == true)? 'new-customer' : 'old-customer',
                                    cartsCount:  resp.data.cartsCount,
                                    cart_id: resp.data.payment.cart_id,
                                    coupon_code: resp.data.coupon_code,
                                    amount: parseFloat((resp.data.payment.cart_amount * conversionRate).toFixed(2)),
                                    ...webengageProducts
                                };

                                // console.log('webEngageData', webEngageData);
                                // console.log('ecommerce', ecommerce);

                                //UNCOMMENT FOR PRODUCTION

                                if (!resp.data.event_fired) {
                                    this.sendWebEngagePaymentData('purchase',webEngageData);

                                    if(resp.data.newCustomer) {
                                      this.sendWebEngagePaymentData('first_purchase',webEngageData);
                                    }

                                    // new Tracker("BUY", resp.data.payment.userId, resp.data.payment.cart_id, resp.data.payment.cart_amount, currency).dispatch();
                                    this.paydata = {
                                        event: 'eec.purchase',
                                        ecommerce
                                        // noninteraction: false // Optional
                                    };
                                    this.$gtm.trackEvent(this.paydata);
                                }
                                // this.transactionClass = 'green--text';
                                // this.transactionRefId = resp.data.ref_id;
                                // this.message = this.successTitle;
                                this.validOrder = true;

                            } else if (resp.data.msg == 'invalid payment') {
                              // this.btn_id = 'ga_purchase_failed';
                              //   this.transactionClass = 'red--text';
                              //   this.transactionRefId = null;
                              //   this.message = this.failedTitle;
                              //   this.validOrder = false;
                            }

                            // this.paymentDialog = true;

                        })
                        .catch(err => {
                            console.log(err)
                        })
                } else {
                    this.redirectToStepper();
                    return;
                }
            },

        },
    }
</script>

<style>

</style>